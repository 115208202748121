import CodeExample from "components/docs/code-examples/CodeExample";
import DocumentationSection from "components/docs/DocumentationSection";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { MERGE_PRICING_PAGE } from "components/docs/navigation/links";
import ParameterRow from "components/docs/ParameterRow";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import { Link } from "gatsby";
import React from "react";
import { RouterLocation } from "types/types";
import { StaticImage } from "gatsby-plugin-image";

interface Props {
  location: RouterLocation;
}

const SupplementalDataRemoteDataPage = ({ location }: Props) => {
  const title = "Remote Data";
  const description =
    "Learn how to get the latest integration data in the original format Merge received it in.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar
        title="Remote Data"
        subtitle="Learn how to get the latest integration data in the original format Merge received it in."
      >
        <PricingInfoCard className="mb-9 mt-0">
          This feature is only available to customers on our <b>Professional</b> or{" "}
          <b>Enterprise</b> plans. View the{" "}
          <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
            {" "}
            Merge Plans
          </a>{" "}
          to learn more.
        </PricingInfoCard>
      </HeaderBar>
      <DocumentationSection title="Overview" className="mb-9">
        <>
          <p>
            Using Merge&apos;s remote data, you can get the latest integration data in the original
            format Merge received it in.
          </p>
          <p>
            Merge will include original data as we saw it from the remote API endpoint, if you set
            Merge&apos;s <code>include_remote_data</code> query parameter to <code>true</code> and
            turn on Remote Data in your{" "}
            <a href="https://app.merge.dev/common-models/" target="_blank" rel="noreferrer">
              dashboard
            </a>
            .
          </p>

          <p className="mt-5">Quick Tips:</p>
          <ol type="1" className="mt-4 pl-5">
            <li>
              <b>Syncing:</b> <code>remote_data</code> will not be updated during recurring syncs
              unless normalized fields (e.g., common model fields or field mappings) are updated.
            </li>
            <li>
              <b>Passthrough:</b> If you&apos;re looking for data from an endpoint that we do not
              include in our common data models, you may need to create an{" "}
              <b>
                <Link to="/supplemental-data/passthrough">Authenticated Passthrough Request</Link>
              </b>{" "}
              instead.
            </li>
            <li className="mb-5">
              <b>Accessing:</b> remote_data is an array of response objects per API path and
              ordering can change over time. While accessing remote data, we highly recommend
              asserting on the specific API path variable instead of accessing{" "}
              <code>remote_data[index]</code>. <code>remote_data[index].path</code> is a variable
              that represents the api path and will differ for each common model/integration.
            </li>
          </ol>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <StaticImage
              src="../../assets/images/illustrations/remote-data.png"
              alt="How Remote Data Works"
              quality={100}
              placeholder="none"
              layout="constrained"
              width={960}
            />
          </div>
        </>
      </DocumentationSection>
      <DocumentationSection title="Query Parameters">
        <>
          <ParameterRow
            key="include_remote_data"
            name="include_remote_data"
            type="Boolean"
            required={false}
            isShowingRequiredFlag={false}
            description="Indicates whether the latest remote data should be included with the requested resource."
          />
          <ParameterRow
            key="remote_fields"
            name="remote_fields"
            type="String"
            required={false}
            isShowingRequiredFlag={false}
            description="Indicates which common model fields should be returned in the original, non-normalized format."
          />
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Fetching with the Merge SDK">
        <>
          <p>See below for an example of how to get remote data via Merge&apos;s SDK:</p>
          <CodeExample
            folder="supplemental-data/remote-data/sdk-fetch"
            codeBlockName="Remote Data via SDK"
          />
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Response">
        <>
          <p>Resources fetched with remote data will look like the following:</p>
          <CodeExample
            folder="supplemental-data/remote-data/remote-data-payload"
            codeBlockName="Remote Data Example"
            colorScheme="light"
            hasLineNumbers={false}
          />
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Show Enum Origins">
        <>
          <p>
            Merge&apos;s Show Enum Origins feature allows you to obtain the value of common model
            enum fields in the original format that Merge received it in. This feature is available
            in all of Merge&apos;s common model API endpoints, where relevant.
          </p>
          <p>
            If you include a common model enum field&apos;s name into the{" "}
            <code>show_enum_origins</code> query parameter, Merge will replace the normalized enum
            value of the specified common model field with the unnormalized value from the
            third-party in the response body. This is useful if you always want to return the
            original value of common model fields as they are represented in your customer&apos;s
            third-party platform, instead of in Merge&apos;s normalized format.
          </p>
          <p>
            When using the <code>show_enum_origins</code> query parameter, Merge will map the
            unnormalized value of the field to the specified common model field so that you can
            reliably access that field&apos;s value in a consistent way, regardless of the
            integration your customer is using.
          </p>
          <p>
            Below is an example response from Merge&apos;s <code>/employees</code> endpoint, where{" "}
            <code>/employees</code> was passed into the query parameters. In this example, instead
            of returning the normalized enum value of &quot;<code>INACTIVE</code>&quot; in the
            employment_status field, we return the original value &quot;
            <code>Terminated</code>&quot; as it was received from the third-party.
          </p>

          <CodeExample
            folder="supplemental-data/remote-data/show-enum-origins-response"
            codeBlockName="Show Enum Origins Example Response"
            colorScheme="light"
            hasLineNumbers={false}
          />
        </>
      </DocumentationSection>
      <CrossGuideLinks location={location} />
    </StaticPageContainer>
  );
};

export default SupplementalDataRemoteDataPage;
